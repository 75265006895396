import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Badge, Stack } from "@mui/material";
import { useWorkerPreferences } from "@src/appV2/Agents/api/useWorkerPreferences";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES } from "@src/constants";

import { useIsShiftBlocksEnabled } from "../../redesign/Shift/Block/useIsShiftBlocksEnabled";
import { useFetchPaginatedShiftBlocks } from "../api/useFetchPaginatedShiftBlocks";

export function ShiftBlockTabTitle() {
  const worker = useDefinedWorker();
  const { filters } = useWorkerPreferences();
  const isShiftBlocksEnabled = useIsShiftBlocksEnabled();
  const qualification = filters?.license ?? worker?.licensesData[0]?.qualification;

  const { data: shiftBlocksData } = useFetchPaginatedShiftBlocks(
    {
      filter: {
        agentId: worker.userId,
        qualification,
        maxDistanceMiles: DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES.toString(),
      },
    },
    {
      enabled: isDefined(worker) && isDefined(qualification) && isShiftBlocksEnabled,
      cacheTime: 5 * 60_000,
      staleTime: 2 * 60_000,
    }
  );
  const totalShiftBlocks = shiftBlocksData?.pages[0]?.data.length ?? 0;
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Text>Blocks</Text>

      <Badge
        color="primary"
        badgeContent={totalShiftBlocks}
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: "transparent",
            color: (theme) => theme.palette.text.primary,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          },
        }}
      />
    </Stack>
  );
}
