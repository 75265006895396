import { useLocalStorage } from "@src/appV2/lib/utils";

export function useEnableDistanceFiltering() {
  const [enableDistanceFiltering, setEnableDistanceFiltering] = useLocalStorage<boolean>(
    "shift-blocks-enable-distance-filtering",
    true
  );

  return {
    enableDistanceFiltering,
    setEnableDistanceFiltering,
  };
}
