import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Stack } from "@mui/material";
import { useWorkerPreferences } from "@src/appV2/Agents/api/useWorkerPreferences";
import { useHCPStreakData } from "@src/appV2/Streaks";
import { checkShiftHasStreakPayBoost } from "@src/appV2/Streaks/utils";
import { type Worker } from "@src/appV2/Worker/api/types";

import { useFetchPaginatedShiftBlocks } from "../api/useFetchPaginatedShiftBlocks";
import { useEnableDistanceFiltering } from "../hooks/useEnableDistanceFiltering";
import { transformIntoShiftBlockWithDetails } from "../utils";
import { EmptyShiftBlock } from "./EmptyShiftBlock";
import { EmptyShiftBlocksFacilityView } from "./EmptyShiftBlocksFacilityView";
import { ShiftBlockCardSkeleton } from "./ShiftBlockCardSkeleton";
import { ShiftBlockItem } from "./ShiftBlockItem";

interface ShiftBlocksProps {
  worker: Worker;
  facilityId?: string;
}

export function ShiftBlocks(props: ShiftBlocksProps) {
  const { worker, facilityId } = props;
  const { filters } = useWorkerPreferences();
  const { hcpStreaksResponseData } = useHCPStreakData(isDefined(facilityId) ? [facilityId] : []);

  const { enableDistanceFiltering } = useEnableDistanceFiltering();

  const qualification = filters?.license ?? worker?.licensesData[0]?.qualification;

  const {
    data: shiftBlocksData,
    isLoading: isShiftBlocksLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isSuccess: isShiftBlocksSuccess,
  } = useFetchPaginatedShiftBlocks(
    {
      filter: {
        agentId: worker.userId,
        facilityId,
        qualification,
        maxDistanceMiles: enableDistanceFiltering ? filters.distance.toString() : undefined,
      },
    },
    {
      enabled: isDefined(qualification),
      meta: {
        userErrorMessage: "Something went wrong while loading shift blocks",
      },
    }
  );
  if (isShiftBlocksSuccess && (shiftBlocksData.pages[0]?.data.length ?? 0) === 0) {
    return isDefined(facilityId) ? (
      <Box
        sx={{
          paddingY: 1.5,
          paddingX: 1,
        }}
      >
        <EmptyShiftBlocksFacilityView />
      </Box>
    ) : (
      <EmptyShiftBlock />
    );
  }

  if (!isDefined(qualification)) {
    return <EmptyShiftBlock />;
  }

  const shiftBlockShiftsMapById = new Map(
    shiftBlocksData?.pages
      .flatMap((page) => page.included)
      .filter((shift) => shift.type === "shift")
      .map((shift) => [shift.id, shift])
  );

  return (
    <Stack spacing={2}>
      {isShiftBlocksLoading && <ShiftBlockCardSkeleton />}
      {/** For the useInfiniteQuery hook, we would want to display successful pages in case one of the future fetched page has an error, therefore checking for isDefined on the data instead of using isSuccess from the hook */}
      {isDefined(shiftBlocksData) && shiftBlocksData.pages.length > 0 && (
        <>
          {shiftBlocksData.pages.flatMap((shiftBlockPage) => {
            return shiftBlockPage.data.map((shiftBlock) => {
              const shiftBlockWithDetails = transformIntoShiftBlockWithDetails(
                shiftBlock,
                shiftBlockPage.included
              );
              return (
                <ShiftBlockItem
                  key={shiftBlock.id}
                  shiftBlock={shiftBlockWithDetails}
                  agentTimezone={worker.tmz}
                  shouldShowFacilityDetails={!isDefined(facilityId)}
                  activeStreaksWithPayBoost={hcpStreaksResponseData.filter(
                    (streak) =>
                      streak.attributes.facilityId === shiftBlockWithDetails.facilityId &&
                      shiftBlockWithDetails.shifts.some((shift) => {
                        const shiftDetails = shiftBlockShiftsMapById.get(shift.id);
                        return (
                          isDefined(shiftDetails) &&
                          "start" in shiftDetails.attributes &&
                          checkShiftHasStreakPayBoost({
                            hcpStreaks: [streak],
                            shiftStart: shiftDetails.attributes.start,
                          })
                        );
                      })
                  )}
                />
              );
            });
          })}

          {hasNextPage && (
            <Button
              fullWidth
              size="small"
              disabled={isFetchingNextPage}
              onClick={async () => await fetchNextPage()}
            >
              Load more blocks
            </Button>
          )}
        </>
      )}
    </Stack>
  );
}
