import { type UseModalState } from "@clipboard-health/ui-react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { type AgentPreferences } from "@src/appV2/Agents/api/useWorkerPreferences/types";
import { type AgentLicenseData } from "@src/appV2/Agents/types";
import { OpenShiftLicenseField } from "@src/appV2/OpenShifts/components/OpenShiftCalendar/Dialogs/LicenseField";
import { useForm } from "react-hook-form";

interface ShiftBlockLicenseFilterDialogProps {
  modalState: UseModalState;
  agentLicenses: AgentLicenseData[];
  onSubmit: (values: AgentPreferences) => void;
  defaultValues: AgentPreferences;
}

export function ShiftLicenseBlockFilterDialog(props: ShiftBlockLicenseFilterDialogProps) {
  const { modalState, agentLicenses, onSubmit, defaultValues } = props;
  const { modalIsOpen, closeModal } = modalState;
  const formMethods = useForm<AgentPreferences>({
    defaultValues,
  });
  const { control, handleSubmit, formState } = formMethods;
  return (
    <Dialog
      fullWidth
      open={modalIsOpen}
      onClose={() => {
        closeModal();
      }}
    >
      {/**
       * Note: The form is near identical to src/appV2/OpenShifts/components/OpenShiftCalendar/Dialogs/LicenseDialogForm.tsx, with the difference that this one does not have a distance filter.
       */}
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit((formData) => {
          onSubmit(formData);
          closeModal();
        })}
      >
        <DialogTitle sx={{ textAlign: "left" }}>Select qualification filter</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={2} marginBottom={2}>
            {agentLicenses.length > 0 && (
              <OpenShiftLicenseField
                name="license"
                control={control}
                agentLicenses={agentLicenses}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            disabled={formState.isSubmitting}
          >
            Apply
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
